<!--
	This is the sign in page, it uses the dashboard layout in:
	"./layouts/Default.vue" .
 -->

<template>
	<div class="sign-in">

		<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

			<!-- Sign In Form Column -->
			<a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 12, offset: 2}" class="col-form">
				<h1 class="mb-15">{{$t('Sign_In')}}</h1>
				<h5 class="font-regular text-muted">{{$t('enter_your_username_and_password_to_sign_in')}}</h5>

				<!-- Sign In Form -->
				<a-form
					id="components-form-demo-normal-login"
					:form="form"
					class="login-form"
					@submit="handleSubmit"
					:hideRequiredMark="true"
				>
					<a-form-item class="mb-10" :label="$t('username')" :colon="false">
						<a-input
						v-decorator="[
						'username',
						{ rules: [{ required: true, message: $t('please_input_your_username') }] },
						]" :placeholder="$t('username')" />
					</a-form-item>
					<a-form-item class="mb-5" :label="$t('password')" :colon="false">
						<a-input
						v-decorator="[
						'password',
						{ rules: [{ required: true, message: $t('please_input_your_password') }] },
						]" type="password" :placeholder="$t('password')" />
					</a-form-item>
					<a-form-item class="mb-5">
            <a href="#" @click.prevent="showForgotPassword">Забыли пароль?</a>
					</a-form-item>
					<a-form-item>
						<a-button type="primary" block html-type="submit" class="login-form-button">
              {{$t('Sign_In')}}
						</a-button>
					</a-form-item>
				</a-form>
				<!-- / Sign In Form -->

<!--				<p class="font-semibold text-muted">Don't have an account? <router-link to="/sign-in" class="font-bold text-dark">Sign Up</router-link></p>-->
			</a-col>
			<!-- / Sign In Form Column -->

			<!-- Sign In Image Column -->
<!--			<a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">-->
<!--				<img src="images/img-signin.jpg" alt="">-->
<!--			</a-col>-->
			<!-- Sign In Image Column -->

		</a-row>
    <a-modal
        v-model="isForgotPasswordModalVisible"
        title="Сброс пароля"
        @ok="requestPasswordReset"
        @cancel="handleCancel"
    >
      <p>Введите свой email для сброса пароля:</p>
      <a-input v-model="resetEmail" placeholder="Email" />
    </a-modal>

	</div>
</template>

<script>
  import axios from '@/configs/axios';
	import {mapActions} from "vuex";

  export default ({
		data() {
			return {
				// Binded model property for "Sign In Form" switch button for "Remember Me" .
				rememberMe: true,
        isForgotPasswordModalVisible: false,
        resetEmail: ''
			}
		},
		beforeCreate() {
			// Creates the form and adds to it component's "form" property.
			this.form = this.$form.createForm(this, { name: 'normal_login' });
		},
		methods: {
      ...mapActions({
        login:'user/login',
        PROFILE_INFO:'user/PROFILE_INFO',
      }),
			// Handles input validation after submission.
			async handleSubmit(e) {
				e.preventDefault();
				this.form.validateFields(async (err, values) => {
					if ( !err ) {
						console.log('Received values of form: ', values) ;
            try {
              await this.login(values).then(async (res)=>{
                if (res.access){
                  await this.PROFILE_INFO().then(async (res_ele)=>{
                    if (res_ele.user.is_staff){
                      this.$router.push({name:'home'});
                    }else{
                      this.$router.push({name:'change_password'});
                    }
                  })
                }
              });
            } catch (error) {
              console.error(error);
            }
					}
				});
        console.log('this.form', this.form)

			},
      showForgotPassword() {
        this.isForgotPasswordModalVisible = true;
      },
      handleCancel() {
        this.isForgotPasswordModalVisible = false;
      },
      requestPasswordReset() {
        this.isForgotPasswordModalVisible = false;
        // Отправляем запрос на сброс пароля
        axios.post('/organizations/password_reset/', {
          email: this.resetEmail
        }).then(response => {
          this.$message.success("Письмо для сброса пароля отправлено!");
        }).catch(error => {
          this.$message.error("Ошибка! Проверьте правильность введенного email.");
        });
      }
		},
	})

</script>

<style lang="scss">
	body {
		background-color: #ffffff;
	}
</style>
